// import React from 'react';
// import headermobile from '../assets/images/header-mobile2.png';
// import   playstore from '../assets/images/PlayStoreBadge.png';
// import  google from '../assets/images/GooglePlayStore.png';

// const Home = () => {
//   return (
//     <header className="home-area overlay" id="home_page">
//       <div className="container">
//         <div className="row">
//           <div className="col-xs-12 hidden-sm col-md-5">
//             <figure className="mobile-image wow fadeInUp" data-wow-delay="0.2s">
//               <img src={headermobile} alt="" />
//             </figure>
//           </div>
//           <div className="col-xs-12 col-md-7">
//             <div className="space-80 hidden-xs"></div>
//             <h2 className="wow fadeInUp" data-wow-delay="0.4s">Khata Book My Spending Tracker</h2>
//             <div className="space-20"></div>
//             <div className="desc wow fadeInUp" data-wow-delay="0.6s">
//               <ul>
//                 <li>Are you worried about your expenses?</li>
//                 <li>Are you worried about your income?</li>
//                 <li>Are you worried about daily bills?</li>
//               </ul>
//             </div>
//             <div className="space-20"></div>
//             <figure className="wow fadeInUp" data-wow-delay="0.8s">
//               <a href="https://apps.apple.com/in/app/khata-book-my-spending-tracker/id1517793704" target="_blank">
//                 <img src={playstore} alt="Download on the App Store" />
//               </a>
//               <a href="https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker" target="_blank">
//                 <img src={google} alt="Get it on Google Play" />
//               </a>
//             </figure>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Home;
import React from 'react';
import headermobile from '../assets/images/header-mobile2.png';
import playstore from '../assets/images/PlayStoreBadge.png';
import google from '../assets/images/GooglePlayStore.png';
import './style.css';


const Home = () => {
  return (
    <header className="home-area overlay" id="home_page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 hidden-sm col-md-5">
            <figure className="mobile-image wow fadeInUp" data-wow-delay="0.2s">
              <img src={headermobile} alt="" />
            </figure>
          </div>
          <div className="col-xs-12 col-md-7">
            <div className="space-80 hidden-xs"></div>
            <h2 className="wow fadeInUp"  style={{fontStyle:"Times new roman"}} data-wow-delay="0.4s"><strong>Khata Tracker</strong></h2>
            <div className="space-20"></div>
            <div className="desc wow fadeInUp" data-wow-delay="0.6s">
              {/* <ul>
                <li>Are you worried about your expenses?</li>
                <li>Are you worried about your income?</li>
                <li>Are you worried about daily bills?</li>
              </ul> */}
              <p>Khata Tracker is a spending tracker that helps you organize your life with ease. Khata Tracker is a simple cash management app for all kinds of businesses. You can manage your daily incomes, expenses, sales, Purchases, credit and debit entries, and all your day-to-day transactions effortlessly.</p>
            <br></br>
            </div>
            <div className="space-20"></div>
            <figure className="wow fadeInUp" data-wow-delay="0.8s">
              <a href="https://apps.apple.com/in/app/khata-book-my-spending-tracker/id1517793704" target="_blank" rel="noopener noreferrer">
                <img src={playstore} alt="Download on the App Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker" target="_blank" rel="noopener noreferrer">
                <img src={google} alt="Get it on Google Play" />
              </a>
            </figure>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Home;
