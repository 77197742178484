import React from 'react';
import Slider from 'react-slick';
import standed from '../assets/css/img/frame.png';
import image1 from '../assets/images/1 .png';
import image2 from '../assets/images/2.png';
import image3 from '../assets/images/3.png';
import image4 from '../assets/images/4.png';
import image5 from '../assets/images/5.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Gallery = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,          // Enable autoplay
    autoplaySpeed: 2000,
  };

  return (
    <div>
      <section className="gallery-area section-padding" id="gallery_page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-lg-5">
              <div className="static-image-container">
                <img src={standed} alt="Static Frame" className="static-image" />
                <div className="carousel-overlay">
                  <Slider {...settings}>
                    <div className="item"><img src={image1} alt="Gallery Image 1" /></div>
                    <div className="item"><img src={image2} alt="Gallery Image 2" /></div>
                    <div className="item"><img src={image3} alt="Gallery Image 3" /></div>
                    <div className="item"><img src={image4} alt="Gallery Image 4" /></div>
                    <div className="item"><img src={image5} alt="Gallery Image 5" /></div>
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-lg-4 offset-lg-1">
              <div className="page-title">
                <h5 className="white-color title">Feature Images</h5>
                <div className="space-10"></div>
              </div>
              <div className="space-20">
              <div className="desc">
             <p>1. Adding a new customer to Khata Tracker is quick and simple. Just enter the party name and mobile number, and if you want, you can also add their address. With a few taps, you can keep all your customer information organized and easily accessible. Start building your customer list effortlessly with Khata Tracker.</p>
             <p>2.Khata Tracker makes managing your customer accounts simple and intuitive. Instantly see your total receivables and payables at a glance. Search for customers easily and view recent transactions with just a tap. Whether you’re keeping track of what you’ve given or received, Khata Tracker keeps everything organized and up-to-date.</p>
             {/* <p>3.Khata Tracker provides a clear and organized view of your financial transactions. Easily set date ranges to filter your records and see who owes what. The app breaks down your total receivables and payables, showing each customer's balance with a timestamp for easy reference. With the ability to search and sort entries, you can quickly find the information you need.</p>
            <p>4.Khata Tracker lets you manage multiple profiles with ease. Whether you’re handling accounts for yourself or others, you can quickly switch between profiles to see the number of customers and suppliers each one has. Adding a new profile is simple with the “Add Profile” button, ensuring you can keep everything organized. With all your profiles in one place, managing your finances has never been more convenient.</p> */}
            {/* <p>5.Khata Tracker makes it easy to monitor your daily finances at a glance. Adding new expenses or income is just a tap away, keeping your records up-to-date effortlessly. The intuitive charts help you visualize your spending and available funds, ensuring you stay on top of your financial health every day.</p> */}
            </div>
              <div className="space-50"></div>
              {/* <a href="#" className="bttn-default">Learn More</a> */}
            </div> 
            </div>
          </div>
        </div>
      </section>

      <style jsx>{`
        .static-image-container {
          position: relative;
          width: 100%;
          max-width: 300px; /* Adjust as needed */
          margin: 0 auto;
        }

        .static-image {
          display: block;
          width: 100%;
          height: auto;
        }

        .carousel-overlay {
          position: absolute;
          top: 10%; /* Adjust as needed */
          left: 10%; /* Adjust as needed */
          width: 80%; /* Adjust as needed */
          height: 90%; /* Adjust as needed */
        }

        .carousel-overlay .item img {
          width: 100%;
          height: auto;
        }

        .page-title {
          margin-top: 20px;
        }

        .bttn-default {
          display: inline-block;
          padding: 10px 20px;
          background-color: #007bff;
          color: white;
          text-decoration: none;
          border-radius: 5px;
          margin-bottom:10%;
          
        }

        .bttn-default:hover {
          background-color: #0056b3;
        }

        .white-color {
          color: white;
        }
      `}</style>
    </div>
  );
};

export default Gallery;
