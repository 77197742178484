import React from 'react';
import download from '../download imgs/download-image.png';
import play from '../images/PlayStoreBadge.png';
import google from '../images/GooglePlayStore.png';
const Download = () => {
  return (
    <div>
      <div className="download-area overlay" id="download">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 hidden-sm">
              <figure className="mobile-image">
                <img src={download} alt="Download the app" />
              </figure>
            </div>
            <div className="col-xs-12 col-md-6 section-padding">
              <h3 className="white-color">Download The App</h3>
              <div className="space-20"></div>
              <p>Khata Tracker features which will help you to know the transaction details with each customer and also send the report to the customer for their reference.</p>
              <div className="space-60"></div>
              <a href="https://apps.apple.com/in/app/khata-book-my-spending-tracker/id1517793704" target="_blank" rel="noopener noreferrer">
                <img src={play} alt="Download on the App Store" style={{marginleft:"10px"}}/>
              </a>
              <a href="https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker" target="_blank" rel="noopener noreferrer">
                <img src={google} alt="Get it on Google Play" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
