import React, { useState } from 'react';
import './style.css';

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleCollapse = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div>
      <section id="questions_page" className="questions-area section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12">
              <div className="page-title text-center">
                <h5 className="title">FAQ</h5>
                <h3 className="dark-color">Frequently Asked Questions</h3>
                <div className="space-60"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="toggole-boxs">
                <div className="faq-item">
                  <h3 onClick={() => toggleCollapse(1)}>What are the key activities of Khata Tracker?</h3>
                  {expandedIndex === 1 && (
                    <div>
                      <p>Khata Tracker offers users a simple alternative. They can record all transactions and maintain a digital record.</p>
                    </div>
                  )}
                </div>
                <div className="faq-item">
                  <h3 onClick={() => toggleCollapse(2)}>Does Khata Tracker share data with government?</h3>
                  {expandedIndex === 2 && (
                    <div>
                      <p>You may be assured that we do not share this data with any third party except in accordance with the terms of this Policy.</p>
                    </div>
                  )}
                </div>
                <div className="faq-item">
                  <h3 onClick={() => toggleCollapse(3)}>What is Khata Tracker information?</h3>
                  {expandedIndex === 3 && (
                    <div>
                      <p>Operated by Kyte Technologies, the Khata Tracker app offers a digital ledger to all small business owners in India. It helps manage books of accounts and offers reminders through WhatsApp and SMS for collecting money and making due payments.</p>
                    </div>
                  )}
                </div>
                <div className="faq-item">
                  <h3 onClick={() => toggleCollapse(4)}>Why more question goes here?</h3>
                  {expandedIndex === 4 && (
                    <div>
                      <p>Khata Tracker is the world's fastest-growing SaaS company. It is India's leading business management app for MSMEs that enables companies to keep a digital log of their financial transactions and accept payments online.</p>
                    </div>
                  )}
                </div>
                <div className="faq-item">
                  <h3 onClick={() => toggleCollapse(5)}>What problem does Khata Tracker solve?</h3>
                  {expandedIndex === 5 && (
                    <div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                    </div>
                  )}
                </div>
                <div className="faq-item">
                  <h3 onClick={() => toggleCollapse(6)}>Can Khata Tracker be used multiple users?</h3>
                  {expandedIndex === 6 && (
                    <div>
                      <p>With this feature, you can manage multiple businesses with the same customer or different. No more worries about carrying different account books. All your business accounts are in your pocket with this one app.</p>
                    </div>
                  )}
                </div>
                <div className="faq-item">
                  <h3 onClick={() => toggleCollapse(7)}>What is the purpose of Khata?</h3>
                  {expandedIndex === 7 && (
                    <div>
                      <p>This document certifies that a property owner has paid all the required property taxes and owns a legal property.</p>
                    </div>
                  )}
                </div>
                <div className="faq-item">
                  <h3 onClick={() => toggleCollapse(8)}>Is Khata Tracker successful?</h3>
                  {expandedIndex === 8 && (
                    <div>
                      <p>Khata Tracker has completed a successful digital lending pilot, and it is now available in all major metros and tier-1 cities in India. The startup expects to clock a loan book size of Rs 1,000 crore in the next 12 months with the new offering.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FAQ;


