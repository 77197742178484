// import React from "react";
// import "./style.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
// import ReCaptcha from './captcha';

// const Contact = () => {
//   const handleButtonClick = (type) => {
//     let url = "mailto:support@khatatracker.com";
//     if (type === 'phone') {
//       url = "tel:+917095044455";
//     }
//     window.open(url, '_blank');
//   };
//   const validateForm = (event) => {
//     event.preventDefault();
//     const name = document.getElementById('name').value;
//     const email = document.getElementById('email').value;
//     const message = document.getElementById('message').value;
//     const phone = document.getElementById('phone').value;
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//     if ( name.length <= 30 && emailPattern.test(email) && email.endsWith(".com") && message && phone.match(/^\d+$/)) {
//         document.getElementById('successMessage').style.display = 'block';
//         // Optionally, you can proceed with form submission here
//     } else {
//         alert('Please fill out all fields correctly. Name should be between 25 and 30 characters. Make sure the email ends with .com and the phone number contains only digits.');
//     }
// };
//   return (
//     <div>
//       <div className="section-padding">
//         <div className="container">
//           <div className="row">
//             <div className="col-xs-12">
//               <div className="page-title text-center">
//                 <h5 className="title">Contact Us</h5>
//                 <h3 className="dark-color">Find Us By Below Details</h3>
//                 <div className="space-60"></div>
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-xs-12 col-sm-4">
//               <div className="footer-box">
//                 <div className="box-icon">
//                   <FontAwesomeIcon icon={faLocationDot} />
//                 </div>
//                 <p>Madhapur, Hyderabad, Telangana - 500081</p>
//               </div>
//               <div className="space-30 hidden visible-xs"></div>
//             </div>
//             <div className="col-xs-12 col-sm-4">
//               <div className="footer-box">
//                 <div className="box-icon">
//                   <FontAwesomeIcon icon={faPhone} />
//                 </div>
//                 <p>+91 7095044455</p>
//               </div>
//               <div className="space-30 hidden visible-xs"></div>
//             </div>
//             <div className="col-xs-12 col-sm-4">
//               <div className="footer-box">
//                 <div className="box-icon">
//                   <FontAwesomeIcon icon={faEnvelope} />
//                 </div>
//                 <p>khatatracker@gmail.com</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container">
//         <div className="row">
//           <div className="col-xs-12 col-lg-12 col-md-12">
//             <form method="post">
//               <div className="form-row">
//                 <div className="row">
//                   <div className="form-group col-xs-12 col-lg-6 col-md-6">
//                     <label>Name</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="name"
//                       name="name"
//                       placeholder="Enter Your Name"
//                       required
//                     />
//                   </div>
//                   <div className="form-group col-xs-12 col-lg-6 col-md-6">
//                     <label>Email</label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="email"
//                       name="email"
//                       placeholder="Enter Your Email"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="form-group col-xs-12 col-lg-12 col-md-12">
//                   <label>Subject</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="subject"
//                     name="subject"
//                     placeholder="Enter Subject"
//                     required
//                   />
//                 </div>
//                 <div className="form-group col-xs-12 col-lg-12 col-md-12">
//                   <label>Message</label>
//                   <textarea
//                     className="form-control"
//                     id="message"
//                     name="message"
//                     placeholder="Type Your Message Here..."
//                     required
//                   ></textarea>
//                 </div>
//               </div>
//               <ReCaptcha/>
//               <center>
//                 <div className="text-center span6">
//                   <button
//                     type="submit"
//                     className="submit"
//                     id="submit"
//                     value="Submit"
//                     onClick={() => handleButtonClick('mail')}
//                     style={{
//                       marginTop: "10px",
//                       marginBottom: "10px",
//                       padding: "10px 10px",
//                       backgroundColor: "#1AA260",
//                       color: "#fff",
//                       border: "none",
//                     }}
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </center>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Contact;


import React, { useState } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const validateForm = (event) => {
    event.preventDefault();
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;
    const phone = document.getElementById("phone").value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      name.length <= 30 &&
      emailPattern.test(email) &&
      email.endsWith(".com") &&
      message &&
      phone.match(/^\d+$/) &&
      captchaVerified
    ) {
      document.getElementById("successMessage").style.display = "block";
      window.open("mailto:support@khatatracker.com", "_blank");
    } else {
      alert(
        "Please fill out all fields correctly. Name should be between 25 and 30 characters. Make sure the email ends with .com and the phone number contains only digits. Also, ensure the CAPTCHA is verified."
      );
    }
  };

  return (
    <div>
      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="page-title text-center">
                <h5 className="title">Contact Us</h5>
                <h3 className="dark-color">Find Us By Below Details</h3>
                <div className="space-60"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <div className="footer-box">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <p>Madhapur, Hyderabad, Telangana - 500081</p>
              </div>
              <div className="space-30 hidden visible-xs"></div>
            </div>
            <div className="col-xs-12 col-sm-4">
              <div className="footer-box">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <p>+91 7095044455</p>
              </div>
              <div className="space-30 hidden visible-xs"></div>
            </div>
            <div className="col-xs-12 col-sm-4">
              <div className="footer-box">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <p>khatatracker@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-12 col-md-12">
            <form method="post" onSubmit={validateForm}>
              <div className="form-row">
                <div className="row">
                  <div className="form-group col-xs-12 col-lg-6 col-md-6">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter Your Name"
                      required
                    />
                  </div>
                  <div className="form-group col-xs-12 col-lg-6 col-md-6">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group col-xs-12 col-lg-12 col-md-12">
                  <label>Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    name="subject"
                    placeholder="Enter Subject"
                    required
                  />
                </div>
                <div className="form-group col-xs-12 col-lg-12 col-md-12">
                  <label>Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    placeholder="Type Your Message Here..."
                    required
                  ></textarea>
                </div>
              </div>
              <ReCAPTCHA
                sitekey="6LcH1xUqAAAAAMb_59kdhJIbhb_APfSPx78DVX59"
                onChange={handleCaptchaChange}
              />
              <center>
                <div className="text-center span6">
                  <button
                    type="submit"
                    className="submit"
                    id="submit"
                    value="Submit"
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      padding: "10px 10px",
                      backgroundColor: "#1AA260",
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </center>
            </form>
            <div id="successMessage" style={{ display: "none", color: "green" }}>
              Form submitted successfully!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

