import React from 'react';
import './style.css'; // Importing external stylesheet
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      {/* Embedding inline styles within the component */}
      <style>
        {`
          a {
            color: #FFD700; /* Setting anchor tag color to gold */
          }
        `}
      </style>

      {/* Footer section */}
      <footer className="footer-area" id="contact_page">
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              {/* Left column */}
              <div className="col-xs-12 col-lg-6 col-md-6">
                <span style={{ color: '#fff' }}>
                  {/* Copyright information */}
                  Copyright &copy; All rights reserved by{' '}
                  <a href="https://www.khatatracker.com/">KHATA TRACKER</a> &nbsp;&nbsp;
                  <a href="privacy/terms_privacy.html"  >Terms & Policy</a>
                  <br />
                  {/* Link to DeleteAccountInstructions component */}
                  <a href="/delete-account" target="_blank" rel="noopener noreferrer">
                    Manage Account
                  </a> &nbsp;&nbsp;
                  <Link to
                        ="/refund" target='_blank'
                    >
                        Refund Policy
                    </Link>
                </span>
                <div className="space-30 hidden visible-xs"></div>
              </div>
              
              {/* Right column */}
              <div className="col-xs-12 col-lg-6 col-md-6" style={{ textAlign: 'center' }}>
                {/* Information about the managing company */}
                <div className="footer-menu">
                  <span style={{ color: '#fff' }}>
                    Managed by{' '}
                    <a href="https://www.anjusiimatechnologies.com/" target='_blank'>
                      ANJU SIIMA TECHNOLOGIES PVT LTD
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
