// import React, { useState } from 'react';
// import './manage.css'
// import file1 from '../assets/privacy/imgs/File (1).jpg';
// import file2 from '../assets/privacy/imgs/File (2).jpg';
// import file3 from '../assets/privacy/imgs/File.jpg';
// import final from '../assets/privacy/imgs/FinalAppIcon.png';
// import short1 from '../assets/privacy/imgs/Screenshot (7).png';
// import short2 from '../assets/privacy/imgs/Screenshot (8).png';
// import short3 from '../assets/privacy/imgs/Screenshot (9).png';

// const DeleteAccountInstructions = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [modalImage, setModalImage] = useState('');
//   const [showContent, setShowContent] = useState('websiteInstructions');

//   const openModal = (imagePath) => {
//     setModalImage(imagePath);
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//   };

//   const showMobileApp = () => {
//     setShowContent('mobileAppInstructions');
//   };

//   const showWebsite = () => {
//     setShowContent('websiteInstructions');
//   };

//   return (
//     <div className="container">
//       <div className="card">
//         <div className="card-body">
//           <h1 style={{ textAlign: 'center' }}>How to delete your account</h1>
//           <div id="buttons-container">
//             <button className="btn btn-primary" onClick={showMobileApp}>Mobile App</button>
//             <button className="btn btn-primary" onClick={showWebsite}>Website</button>
//           </div>

//           <div id="instructions" style={{ display: showContent === 'instructions' ? 'block' : 'none' }}>
//             Your account can be deleted, even if you accidentally delete your account. It is an irrevocable action that we are unable to undo.
//           </div>

//           <div id="mobileAppInstructions" style={{ display: showContent === 'mobileAppInstructions' ? 'block' : 'none' }}>
//             <h2>To delete your account</h2>
//             <ul>
//               <li className="image-container " onClick={() => openModal(final)}>
//                 Open khatabook <img src={final} alt="AppIcon"  />
//               </li>
//               <li className="image-container " onClick={() => openModal(file3)}>
//                 <img src={file3} alt="FileImage"   /> Tap Settings -- Login
//               </li>
//               <li>Enter your login credentials & tap Login button</li>
//               <li className="image-container" onClick={() => openModal(file2)}>
//                 Select Delete Account on the screen <img src={file2} alt="FileImage2" className="showing-img"  />
//               </li>
//               <li className="image-container" onClick={() => openModal(file1)}>
//                 Press Ok <img src={file1} alt="FileImage1" className="showing-img"  />
//               </li>
//             </ul>
//           </div>

//           <div id="websiteInstructions" style={{ display: showContent === 'websiteInstructions' ? 'block' : 'none' }}>
//             <h2>To delete your account</h2>
//             <ul>
//               <li>Delete Account <a href="/delete-account">click here</a></li>
//               <li>Open the <a href="https://www.khatatracker.com/" target="_blank" rel="noopener noreferrer">khatatracker</a></li>
//               <li>Scroll down --- Manage Account</li>
//               <li className="image-container" onClick={() => openModal(short1)}>
//                 Enter your login credentials & tap Login button <img src={short1} alt="Screenshot7" className="showing-img"  />
//               </li>
//               <li className="image-container" onClick={() => openModal(short2)}>
//                 Press Ok <img src={short2} alt="Screenshot8"  className="showing-img"  />
//               </li>
//               <br />
//               <li className="image-container" onClick={() => openModal(short3)}>
//                 Press Ok <img src={short3} alt="Screenshot9" className="showing-img"   />
//               </li>
//             </ul>
//           </div>

//           <div id="ganesh">
//             <h2>If you delete your account:</h2>
//             <ul>
//               <li>Account retrieval is impossible after deleting your Khatabook account.</li>
//               <li>Deletion of information may takes within 24 hrs.</li>
//               <li>Backups containing your data may exist for recovery after the two-day period in case of a disaster or data loss incident.</li>
//               <li>Access to your Khatabook information is not available during this deletion period.</li>
//               <li>Certain log data may be retained post-deletion, but it will be dissociated from personally identifiable information.</li>
//               <li>Regular removal of specific identifiers prevents tracing back to your account.</li>
//               <li>Your information shared with other Meta will also be deleted.</li>
//             </ul>
//           </div>

//           {showModal && (
//             <div id="modalContainer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#1AA267' }}>
//               <div id="myModal" className="modal">
//                 <span className="close" onClick={closeModal}>&times;</span>
//                 <img id="modalImage" src={modalImage} alt="ModalImage" style={{ width: '300px', height: '350px' }} />
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DeleteAccountInstructions;




import { useState } from 'react';
import './manage.css'
import Modal from 'react-bootstrap/Modal';
import file1 from '../assets/privacy/imgs/File (1).jpg';
import file2 from '../assets/privacy/imgs/File (2).jpg';
import file3 from '../assets/privacy/imgs/File.jpg';
import final from '../assets/privacy/imgs/FinalAppIcon.png';
import short1 from '../assets/privacy/imgs/Screenshot (7).png';
import short2 from '../assets/privacy/imgs/Screenshot (8).png';
import short3 from '../assets/privacy/imgs/Screenshot (9).png';

function DeleteAccountInstructions() {
  const [show, setShow] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setCurrentImage(image);
    setShow(true);
  };

  const [showContent, setShowContent] = useState('websiteInstructions');

  const showMobileApp = () => setShowContent('mobileAppInstructions');
  const showWebsite = () => setShowContent('websiteInstructions');

  return (
    <>
      <div className="container">
        <div className="card">
          <div className="card-body">
            <h1 style={{ textAlign: 'center' }}>How to delete your account</h1>
            <div id="buttons-container">
              <button className="btn btn-primary" onClick={showMobileApp}>Mobile App</button>
              <button className="btn btn-primary" onClick={showWebsite}>Website</button>
            </div>

            <div id="mobileAppInstructions" style={{ display: showContent === 'mobileAppInstructions' ? 'block' : 'none' }}>
              <h2>To delete your account</h2>
              <ul>
                <li className="image-container">
                  Open Khatabook <img src={final} alt="Khatabook home screen" className="showing-img" onClick={() => handleShow(final)} />
                </li>
                <li className="image-container">
                  Tap Settings -- Login <img src={file3} alt="Settings screen" className="showing-img" onClick={() => handleShow(file3)} />
                </li>
                <li className="image-container">
                  Select Delete Account on the screen <img src={file2} alt="Delete account screen" className="showing-img" onClick={() => handleShow(file2)} />
                </li>
                <li className="image-container">
                  Press Ok <img src={file1} alt="Confirmation screen" className="showing-img" onClick={() => handleShow(file1)} />
                </li>
              </ul>
            </div>

            <div id="websiteInstructions" style={{ display: showContent === 'websiteInstructions' ? 'block' : 'none' }}>
              <h2>To delete your account</h2>
              <ul>
                <li>Delete Account <a href="/delete-account">click here</a></li>
                <li>Open the <a href="https://www.khatatracker.com/" target="_blank" rel="noopener noreferrer">Khatatracker</a></li>
                <li>Scroll down --- Manage Account</li>
                <li className="image-container">
                  Enter your login credentials & tap Login button <img src={short1} alt="Login screen" className="showing-img" onClick={() => handleShow(short1)} />
                </li>
                <li className="image-container">
                  Press Ok <img src={short2} alt="Ok button screen" className="showing-img" onClick={() => handleShow(short2)} />
                </li>
                <li className="image-container">
                  Press Ok <img src={short3} alt="Ok button confirmation screen"  className="showing-img"onClick={() => handleShow(short3)} />
                </li>
              </ul>
            </div>

            <div id="ganesh">
              <h2>If you delete your account:</h2>
              <ul>
                <li>Account retrieval is impossible after deleting your Khatabook account.</li>
                <li>Deletion of information may take within 24 hrs.</li>
                <li>Backups containing your data may exist for recovery after the two-day period in case of a disaster or data loss incident.</li>
                <li>Access to your Khatabook information is not available during this deletion period.</li>
                <li>Certain log data may be retained post-deletion, but it will be dissociated from personally identifiable information.</li>
                <li>Regular removal of specific identifiers prevents tracing back to your account.</li>
                <li>Your information shared with other Meta will also be deleted.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}  backdrop={false}>
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentImage && <img src={currentImage} alt="Detailed view" />}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteAccountInstructions;

    





