import React from 'react';
import './style.css';
import process from '../assets/images/progress-phone (1).png'
const OurProcess = () => {
  return (
    <div>
      <section className="progress-area gray-bg" id="progress_page">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="page-title section-padding">
                <h5 className="title wow fadeInUp" data-wow-delay="0.2s">Why Use Khata Tracker App?</h5>
                {/* <div className="space-10"></div>
                <h3 className="dark-color wow fadeInUp" data-wow-delay="0.4s">It's a Good Application</h3> */}
                <div className="space-20"></div>
                <div className="desc wow fadeInUp" data-wow-delay="0.6s">
                  <ul>
                    <li>Simple and user-friendly interface for easy navigation and data input.</li>
                    <li> Ability to create multiple ledgers for different business or personal accounts.</li>
                    <li>Real-time balance updates and transaction history for each ledger.</li>
                    <li> Customizable ledger names and categories to match your business needs.</li>
                    <li>Option to export data in PDF format for easy bookkeeping</li>
                    <li>Automatic backup and sync of data across devices.</li>
                    <li>Secure login with passcode and fingerprint authentication.</li>
                    <li>Ability to add notes and images to transactions for detailed record keeping.</li>
                  </ul>
                </div>
                <div className="space-50"></div>
                {/* <a href="#" className="bttn-default wow fadeInUp" data-wow-delay="0.8s">Learn More</a> */}
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <figure className="mobile-image">
                <img src={process} alt="Progress Phone"/>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OurProcess;
