import React from 'react';
import './style.css'
const About = () => {
  return (
    <section className="section-padding" id="about_page">
      <div className="container">
        <div className="text-center">
          <div className="col-xs-12 col-md-10 col-md-offset-1">
            <div className="page-title mass">
              <div className="space-20"></div>
              <h5 className="title">ABOUT KHATA TRACKER</h5>
              <div className="space-30"></div>
              {/* <h3 className="blue-color">Khata Book Pocket Money Expanse Manager is Spending Tracker helps you to organise life easy</h3> */}
              {/* <div className="space-20"></div> */}
              <p>
              Khata Tracker simplifies personal finance management by allowing you to record daily expenses, categorize spending, and set budgets. It offers detailed reports and visual charts to provide insights into your spending patterns, helping you make informed financial decisions. Your data is securely accessible across all your devices, ensuring convenience and security. By understanding where your money goes, you can reduce unnecessary expenses and prioritize saving for future goals. Join the Khata Tracker community to take charge of your finances, stay organized, and make every penny count!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
